"use client";

import { useAppStore } from "@/stores/useAppStore";
import { FunctionalGroups, Locations } from "@/types/review";

interface FiltersStoreProps {
  locations: Locations[];
  functionalGroups: FunctionalGroups[];
}

export function LocationFunctionalFilterStoreInitializer({
  locations,
  functionalGroups,
}: FiltersStoreProps) {
  const { setLocations, setFunctionalGroups } = useAppStore.getState();
  const initializedLocationFunctionalData = useAppStore(
    (state) => state.initializedLocationFunctionalData
  );
  if (!initializedLocationFunctionalData) {
    setLocations(locations);
    setFunctionalGroups(functionalGroups);
  }
  return null;
}
