import { useAppStore } from "@/stores/useAppStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@ui/components/ui/select";
import { Label } from "@ui/components/ui/label";
import "./style.css";

type DateFilterProps = {
  enabled?: boolean;
};

export const DateFilter = ({ enabled = true }: DateFilterProps) => {
  const { setFilters } = useAppStore.getState();
  const dateOptions = [
    { label: "Last 3 Months", value: "3" },
    { label: "Last 12 Months", value: "12" },
    { label: "Last 36 Months", value: "36" },
  ];

  return (
    enabled && (
      <div className="header-filter-wrapper">
        <Label className="header-filter-label text-2xs">Time Range</Label>
        <Select
          onValueChange={(value) => {
            setFilters("selectedMonths", value);
          }}
          defaultValue={useAppStore.getState().filters.selectedMonths}
        >
          <SelectTrigger className="header-filter-select">
            <SelectValue placeholder="Select a date" />
            <SelectContent>
              {dateOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectTrigger>
        </Select>
      </div>
    )
  );
};
