"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { SidebarNavItem } from "@ui/types/nav";
import { Button, buttonVariants } from "@ui/components/ui/button";
import { cn } from "@ui/lib/utils";
import React from "react";
import { Icons } from "@ui/components/icons";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/components/ui/accordion";
export interface SidebarNavProps {
  items: SidebarNavItem[];
}

export function SidebarNav({ items }: SidebarNavProps) {
  const pathname = usePathname();
  return items.length ? (
    <div className="w-full">
      {items.map((item, index) => (
        <div key={index} className={cn("pb-6")}>
          <h4 className="mb-1 rounded-md px-2 py-1 text-sm font-semibold text-center lg:text-left">
            {item.title}
          </h4>
          <div className="flex flex-col text-sm gap-2 lg:items-start items-center">
            {item?.items?.length && <SidebarNavItems items={item.items} />}
          </div>
        </div>
      ))}
    </div>
  ) : null;
}

interface SidebarNavItemsProps {
  items: SidebarNavItem[];
}

export function SidebarNavItems({ items }: SidebarNavItemsProps) {
  const pathname = usePathname();
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={`side-nav-${index}`}>
          {item.disabled ? (
            <span
              key={index}
              className={cn(
                "ml-2 flex w-full cursor-not-allowed lg:justify-start justify-center items-center rounded-md p-2 text-muted-foreground hover:underline px-0",
                item.disabled && "cursor-not-allowed opacity-60"
              )}
            >
              {item.icon &&
                React.createElement(Icons[item.icon], {
                  className: "mr-2",
                  size: 20,
                })}
              {item.title && (
                <span className="hidden lg:block">{item.title}</span>
              )}
              {item.label && (
                <span className="ml-2 rounded-md bg-muted px-1.5 py-0.5 text-xs leading-none text-muted-foreground no-underline group-hover:no-underline">
                  {item.label}
                </span>
              )}
            </span>
          ) : item.href && !item.disabled ? (
            <React.Fragment key={`${index}-${item.href}`}>
              <Link
                href={item.href}
                title={`Open ${item.title}`}
                className={cn(
                  buttonVariants({ variant: "ghost" }),
                  item.disabled && "cursor-not-allowed opacity-60",
                  pathname === item.href
                    ? "font-medium text-primary"
                    : "text-muted-foreground",
                  "justify-start px-2 w-full"
                )}
                target={item.external ? "_blank" : ""}
                rel={item.external ? "noreferrer" : ""}
              >
                {item.icon &&
                  React.createElement(Icons[item.icon], {
                    className: "mr-2",
                    size: 20,
                  })}
                {item.title && (
                  <span className="hidden lg:block">{item.title}</span>
                )}
                {item.label && (
                  <span className="ml-2 rounded-md bg-[#adfa1d] px-1.5 py-0.5 text-xs leading-none no-underline group-hover:no-underline">
                    {item.label}
                  </span>
                )}
              </Link>
            </React.Fragment>
          ) : (
            <React.Fragment key={`${index}-acc`}>
              {item.items.length > 0 && (
                <Accordion
                  type="single"
                  defaultValue="item-1"
                  collapsible
                  className="text-muted-foreground w-full"
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger asChild>
                      <Button
                        variant="ghost"
                        title={`Open ${item.title}`}
                        className="text-muted-foreground justify-start px-2 w-full"
                      >
                        {item.icon &&
                          React.createElement(Icons[item.icon], {
                            className: "mr-2",
                            size: 20,
                          })}
                        {item.title && (
                          <span className="hidden lg:block text-xs">
                            {item.title}
                          </span>
                        )}
                      </Button>
                    </AccordionTrigger>
                    <AccordionContent className="py-0 px-[30px]">
                      <SidebarNavItems
                        key={`acc-${item.href}`}
                        items={item.items}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </>
  );
}
