import {
  Select,
  SelectContent,
  SelectItem,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@ui/components/ui/select";
import { useAppStore } from "@/stores/useAppStore";
import { Label } from "@ui/components/ui/label";
type SourceFilterProps = {
  enabled?: boolean;
};
import "./style.css";
import { sourcePlatforms } from "@/constants/constants";

export const SourceFilter = ({ enabled = true }: SourceFilterProps) => {
  const { setFilters } = useAppStore.getState();

  const selectOptions = sourcePlatforms.map((val) => {
    return val;
  });

  if (!enabled) {
    return <></>;
  }

  return (
    <div className="header-filter-wrapper">
      <Label className="header-filter-label text-2xs">Source</Label>
      <Select
        onValueChange={(value) => {
          setFilters("selectedSource", value);
        }}
        defaultValue={useAppStore.getState().filters.selectedSource}
      >
        <SelectTrigger className="header-filter-select">
          <SelectValue placeholder="Select a source" />
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Source</SelectLabel>
              {selectOptions.map((option) => (
                <SelectItem key={option.value} value={option.value.toString()}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </SelectTrigger>
      </Select>
    </div>
  );
};
