import Link from "next/link";
import { Icons } from "./icons";
import { cn } from "@ui/lib/utils";

export function Logo(props: { siteName: string; isSideNav?: boolean }) {
  const { siteName, isSideNav = false } = props;
  return (
    <Link
      href="/"
      className={cn(
        "flex items-center space-x-2",
        !isSideNav && "lg:hidden",
        isSideNav &&
          "hidden justify-center h-16 md:flex -ml-4 md:pl-0 lg:justify-start lg:ml-6"
      )}
    >
      <Icons.logo className="h-6 w-6" />
      <span
        className={cn(
          "font-bold",
          !isSideNav && "block lg:hidden",
          isSideNav && "hidden lg:block"
        )}
      >
        {siteName}
      </span>
    </Link>
  );
}
