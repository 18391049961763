"use client";
import { deleteCookie } from "@/actions/squeeze-cookie";
import { Button } from "@ui/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@ui/components/ui/dropdown-menu";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { useAppStore } from "@/stores/useAppStore";
import { Icons } from "@ui/components/icons";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { Organization } from ".prisma/client";
import { format } from "date-fns";

export function ProfileMenu() {
  const router = useRouter();
  const {
    organizationMemberRoles: orgRoles,
    currentOrg,
    isSuperAdmin,
  } = useAppStore.getState();
  const [roles, setOrgRoles] = useState(false);
  const [expiryDate, setExpiryDate] = useState<string>();
  const [superAdmin, setSuperAdmin] = useState(isSuperAdmin());
  async function onClickSignOut() {
    await deleteCookie();
    const response = await signOut({ redirect: false, callbackUrl: "/login" });
    if (response?.url) {
      router.replace(response?.url ?? "/");
    }
  }
  async function onClickHandler() {
    router.replace("/brands");
  }
  useEffect(() => {
    if (orgRoles) {
      setOrgRoles(true);
      setSuperAdmin(isSuperAdmin());
    }
    setExpiryDate(
      format(
        new Date(
          ((currentOrg as Organization).subscriptionExpiry as Date) ?? null
        ),
        "MMM dd yyyy"
      )
    );
  }, [currentOrg, orgRoles, isSuperAdmin]);

  return (
    <div className="flex flex-1 items-center justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="w-9 px-0">
            <Icons.user className="h-[1.2rem] w-[1.2rem] scale-100" />
            <span className="sr-only">Open Profile menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>
            {(currentOrg as Organization).name}
          </DropdownMenuLabel>
          <DropdownMenuLabel>
            <span className="mr-2">Expires: {expiryDate}</span>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link href="/profile">Profile</Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {roles && (
            <>
              <DropdownMenuLabel>Admin</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => onClickHandler()}>
                  Switch Brand
                </DropdownMenuItem>

                {superAdmin && (
                  <DropdownMenuItem asChild>
                    <Link href="/admin">App Admin</Link>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuItem onClick={() => onClickSignOut()}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
