"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { MainNavItemType } from "@ui/types/nav";
import { cn } from "@ui/lib/utils";
import { Logo } from "./logo";

interface MainNavProps {
  items?: MainNavItemType[];
  siteName: string | "";
}

interface MainNavItemProps {
  item: MainNavItemType;
}

function MainNavItem({ item }: MainNavItemProps) {
  const pathname = usePathname();
  if (item.regexMatch) {
    const isActiveTitle = new RegExp(item.regexMatch).test(pathname);
    return (
      isActiveTitle && (
        <h1 className="text-lg md:text-2xl lg:text-3xl font-medium">
          {item.title}
        </h1>
      )
    );
  }

  return (
    item.href && (
      <Link
        href={item.href}
        title={`Open ${item.title}`}
        className={cn(
          "flex items-center text-sm font-medium text-muted-foreground",
          item.disabled && "cursor-not-allowed opacity-80"
        )}
      >
        {item.title}
      </Link>
    )
  );
}

export function MainNav({ items, siteName }: MainNavProps) {
  return (
    <div className="flex gap-6 md:gap-10">
      <Logo siteName={siteName} />
    </div>
  );
}
