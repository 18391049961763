import {
  Select,
  SelectContent,
  SelectItem,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@ui/components/ui/select";
import { useAppStore } from "@/stores/useAppStore";
import { Label } from "@ui/components/ui/label";
type CompetitorFilterProps = {
  hasIndex?: boolean;
  enabled?: boolean;
};
import "./style.css";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@ui/components/ui/tooltip";

export const CompetitorFilter = ({
  enabled = true,
  hasIndex = false,
}: CompetitorFilterProps) => {
  const { setFilters, competitors } = useAppStore.getState();

  const selectOptions = competitors.map((val) => {
    return {
      value: val.name,
      label: val.name,
      displayName: val.displayName,
    };
  });

  const selectedOptions = competitors.find((val) => {
    return val.name == useAppStore.getState().filters.selectedCompetitor;
  });

  return (
    enabled && (
      <div className="header-filter-wrapper">
        <Label className="header-filter-label text-2xs">Compare To</Label>
        <TooltipProvider>
          <Select
            onValueChange={(value) => {
              setFilters("selectedCompetitor", value);
            }}
            defaultValue={useAppStore.getState().filters.selectedCompetitor}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <SelectTrigger className="header-filter-select">
                  <SelectValue placeholder="Select a competitor" />
                </SelectTrigger>
              </TooltipTrigger>
              <TooltipContent>
                <p>{selectedOptions?.name}</p>
              </TooltipContent>
            </Tooltip>
            <SelectContent>
              {hasIndex && (
                <SelectGroup>
                  <SelectLabel>Competitive Baskets</SelectLabel>
                  <SelectItem value="Index">Index Avg.</SelectItem>
                </SelectGroup>
              )}
              <SelectGroup>
                <SelectLabel>Competitors</SelectLabel>
                {selectOptions.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value.toString()}
                  >
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span>
                          {option.displayName
                            ? option.displayName
                            : option.label}
                        </span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{option.label}</p>
                      </TooltipContent>
                    </Tooltip>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </TooltipProvider>
      </div>
    )
  );
};
