import {
  Select,
  SelectContent,
  SelectItem,
  SelectGroup,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@ui/components/ui/select";
import { useAppStore } from "@/stores/useAppStore";
import { Label } from "@ui/components/ui/label";
type SourceFilterProps = {
  enabled?: boolean;
};
import "./style.css";

export const LocationFilter = ({ enabled = true }: SourceFilterProps) => {
  const { setFilters, locations } = useAppStore.getState();

  const selectOptions = locations
    .filter((val) => val && val.location)
    .map((val) => {
      return {
        value: val.location,
        label: val.location,
      };
    });

  if (!enabled) {
    return <></>;
  }

  return (
    <div className="header-filter-wrapper">
      <Label className="header-filter-label text-2xs">Location</Label>
      <Select
        onValueChange={(value) => {
          setFilters("selectedLocation", value);
        }}
        defaultValue={useAppStore.getState().filters.selectedLocation}
      >
        <SelectTrigger className="header-filter-select">
          <SelectValue placeholder="Select a location" />
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Location</SelectLabel>
              {selectOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={option.value}
                  className="capitalize"
                >
                  <span className="capitalize">{option.label}</span>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </SelectTrigger>
      </Select>
    </div>
  );
};
